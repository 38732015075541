<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="800px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="body-ctan">
        <el-form ref="form" :model="form" :rules="rules" label-width="140px">
          <el-form-item label="店铺名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="店铺电话：" prop="mobile">
            <el-input v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="门店头像：" prop="shopimages">
            <uploads v-model="form.shopimages"></uploads>
          </el-form-item>
          <el-form-item label="门店组图：" prop="images">
            <uploads v-model="form.images"></uploads>
          </el-form-item>
          <el-form-item label="营业开始时间：" prop="starttime">
            <el-time-picker
              v-model="form.starttime"
              :clearable="false"
            ></el-time-picker>
          </el-form-item>
          <el-form-item label="营业结束时间：" prop="endtime">
            <el-time-picker
              v-model="form.endtime"
              :clearable="false"
            ></el-time-picker>
          </el-form-item>
          <el-form-item label="地图定位地址：" prop="addressMap">
            <div class="map-search-ctan">
              <el-input v-model="form.addressMap" disabled> </el-input>
              <el-button
                class="map-search-btn"
                type="info"
                @click="mapShow = true"
                >选择地址获取经纬度</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label-width="0">
            <div
              style="display: flex; justify-content: space-between; width: 100%"
            >
              <el-form-item label="经度" prop="lng" label-width="120px">
                <el-input v-model="form.lng" type="number" disabled></el-input>
              </el-form-item>
              <el-form-item label="纬度" prop="lat" label-width="120px">
                <el-input v-model="form.lat" type="number" disabled></el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="所在城市：" prop="regions">
            <el-cascader
              ref="regionCascader"
              :props="{ lazy: true, label: 'name', lazyLoad: regionLazyLoad }"
              v-model="form.regions"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="详细地址：" prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item label="门店类型：" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio
                v-for="(item, index) in SHOP_TYPE_OPTIONS"
                :key="index"
                :label="item.value"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="0元畅饮范围(米)："
            prop="freeRange"
            v-if="form.type == '2'"
          >
            <el-input v-model="form.freeRange"></el-input>
          </el-form-item>
          <el-form-item label="可见范围(千米)：" prop="visibleRange">
            <el-input v-model="form.visibleRange"></el-input>
          </el-form-item>
          <el-form-item label="公告：" prop="notice">
            <el-input v-model="form.notice" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="绑定管理员：" prop="adminIds">
            <el-select
              v-model="form.adminIds"
              placeholder="绑定的管理员才可以接收新订单通知和编辑店铺信息"
              multiple
              filterable
            >
              <el-option
                v-for="item in adminOptions"
                :key="item.value"
                :label="item.nickname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="小票打印机：" prop="uniprintId">
            <el-select v-model="form.uniprintId" clearable>
              <el-option
                v-for="item in printOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="图片打印机：" prop="imgprintId">
            <el-select v-model="form.imgprintId" clearable>
              <el-option
                v-for="item in printOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否营业：">
            <el-radio-group v-model="form.status">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>

    <lbsmap v-model="mapShow" @selected="mapSelectedHandle"></lbsmap>
  </div>
</template>

<script>
import {
  addShop,
  editShop,
  getAdminOptions,
  getPrintOptions,
} from "@/apis/auth";
import { updateById, save } from "@/apis/unidrink/shop";
import { SHOP_TYPE, SHOP_TYPE_OPTIONS } from "@/helpers/constants";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
import { getRegion } from "@/apis/common";
import uploads from "@/components/uploads";
import lbsmap from "@/components/lbsmap";
import { clone } from "@babel/types";
export default {
  components: { uploads, lbsmap },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      SHOP_TYPE_OPTIONS: SHOP_TYPE_OPTIONS,
      initForm: {
        name: "",
        mobile: "",
        starttime: new Date(),
        endtime: new Date(),
        addressMap: "",
        regions: [],
        address: "",
        lng: "",
        lat: "",
        adminIds: [],
        status: 1,
        type: 1,
      },
      form: {},
      rules: {
        name: [
          { required: true, message: "店铺名称不能为空", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "店铺电话不能为空", trigger: "blur" },
        ],
        starttime: [
          { required: true, message: "营业开始时间不能为空", trigger: "blur" },
        ],
        endtime: [
          { required: true, message: "营业结束时间不能为空", trigger: "blur" },
        ],
        addressMap: [
          { required: true, message: "地图定位不能为空", trigger: "blur" },
        ],
        lng: [{ required: true, message: "经度不能为空", trigger: "blur" }],
        lat: [{ required: true, message: "纬度不能为空", trigger: "blur" }],
        regions: [
          { required: true, message: "所在城市不能为空", trigger: "blur" },
        ],
        address: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
        ],
        adminIds: [
          { required: true, message: "绑定管理员不能为空", trigger: "blur" },
        ],
        visibleRange: [
          { required: true, message: "可见范围不能为空", trigger: "blur" },
        ],
        freeRange: [
          {
            required: () => {
              return this.form.type == "2";
            },
            message: "可见范围不能为空",
            trigger: "blur",
          },
        ],
        // shopimages: [
        //   { required: true, message: "门店头像不能为空", trigger: "blur" },
        // ],
        // images: [
        //   { required: true, message: "门店组图不能为空", trigger: "blur" },
        // ],
        // uniprintId: [
        //   { required: true, message: "小票打印机不能为空", trigger: "blur" },
        // ],
      },
      adminOptions: [],
      printOptions: [],
      mapShow: false,
    };
  },

  emits: ["update:modelValue", "refresh"],

  created() {
    this.form = cloneDeep(this.initForm);
  },

  watch: {
    modelValue(val) {
      if (val) {
        this.getAdminList();
        this.getPrintList();
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit") {
        this.form = cloneDeep(this.editData);
        this.form.starttime = new Date(Number(this.form.starttime) * 1000);
        this.form.endtime = new Date(Number(this.form.endtime) * 1000);
        this.form.adminIds = this.form.adminId
          .split(",")
          .map((item) => Number(item));
        if (this.form.uniprintId && !isNaN(this.form.uniprintId)) {
          this.form.uniprintId = Number(this.form.uniprintId);
        }
        if (this.form.imgprintId && !isNaN(this.form.imgprintId)) {
          this.form.imgprintId = Number(this.form.imgprintId);
        }
        this.form.regions = [
          this.form.province,
          this.form.city,
          this.form.area,
        ];
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        console.log(this.form);
        let form = cloneDeep(this.form);
        form.adminId = form.adminIds.join(",");
        delete form.adminIds;

        form.province = form.regions[0];
        form.city = form.regions[1];
        form.area = form.regions[2];
        delete form.regions;

        form.starttime = Math.floor(form.starttime.getTime() / 1000);
        form.endtime = Math.floor(form.endtime.getTime() / 1000);
        if (this.mode !== "edit") {
          form.starttime = Math.floor(new Date().getTime() / 1000);
          form.endtime = Math.floor(new Date().getTime() / 1000);
        }
        const promise = this.mode === "edit" ? updateById : save;

        promise(form).then(({ code, message }) => {
          if (code === "0000") {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        });
      });
    },

    transDate(hhmm) {
      const h = hhmm.substr(0, 2);
      const m = hhmm.substring(2);
    },

    regionLazyLoad(node, resolve) {
      const { level } = node;
      const province = [1, 2].includes(level) ? node.pathValues[0] : null;
      const city = level === 2 ? node.pathValues[1] : null;
      getRegion(province, city).then(({ code, data, msg }) => {
        if (code === 1) {
          resolve(
            data.map((item) => {
              return {
                ...item,
                leaf: level >= 2,
              };
            })
          );
        }
      });
    },

    getAdminList() {
      const page = 1;
      const limit = 10;
      this.adminOptions = [];
      const fn = (page, limit) => {
        getAdminOptions(page, limit).then(({ list, total }) => {
          this.adminOptions.push(...list);
          if (page * limit < total) {
            fn(page + 1, limit);
          }
        });
      };
      fn(page, limit);
    },

    getPrintList() {
      const page = 1;
      const limit = 10;
      this.printOptions = [];
      const fn = (page, limit) => {
        getPrintOptions(page, limit).then(({ list, total }) => {
          this.printOptions.push(...list);
          if (page * limit < total) {
            fn(page + 1, limit);
          }
        });
      };
      fn(page, limit);
    },

    mapSelectedHandle({ label, address, point }) {
      console.log("mapSelectedHandle", label, point);
      this.form.addressMap = address;
      this.form.lng = point.lng;
      this.form.lat = point.lat;
    },
  },
};
</script>
,
<style lang="scss" scoped>
.map-search-ctan {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.map-search-btn {
}
</style>

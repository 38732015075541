<template>
  <page-list>
    <template #header>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" inline="true">
        <el-form-item label="所属门店：" prop="shopId">
          <el-select
            v-model="form.shopId"
            placeholder="请选择"
            :disabled="mode === 'edit'"
            @change="shopChangeHandle"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in shopOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
          editMode = 'add';
          dialogVisible = true;
        "
          >添 加</el-button>
        </el-form-item>
      </el-form>
    </template>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      v-loading="loading"
      element-loading-background="transparent"
      @sort-change="sortChangeHandle"
      @row-dblclick="editHandle"
    >
      <el-table-column prop="id" label="ID" min-width="40px" header-align="center" align="center"></el-table-column>
      <el-table-column
        prop="shopId"
        label="展示店铺"
        header-align="center"
        align="center"
        min-width="150px"
        show-overflow-tooltip
        :formatter="shopFormatter"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="包厢名称"
        header-align="center"
        align="center"
        min-width="120px"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="createtime"
        label="创建时间"
        :formatter="
          (row) => $moment.unix(row.createtime).format('yyyy-MM-DD HH:mm:ss')
        "
        min-width="150px"
      ></el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="updatetime"
        label="更新时间"
        :formatter="
          (row) => $moment.unix(row.updatetime).format('yyyy-MM-DD HH:mm:ss')
        "
        min-width="150px"
      ></el-table-column>
      <el-table-column v-if="mode == 'view'" label="操作" header-align="center" align="center">
        <template v-slot="scope">
          <el-button class="icon-btn fa fa-pencil" type="primary" @click="editHandle(scope.row)">
            <!-- 编辑 -->
          </el-button>
          <el-button class="icon-btn fa fa-trash" type="danger" @click="delHandle(scope.row)">
            <!-- 删除 -->
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-model="paginationData" :total="total" @change="getList"></pagination>

    <EditDialog
      v-model="dialogVisible"
      @refresh="getList"
      :editData="editData"
      :shopOptions="shopOptions"
      :mode="editMode"
    ></EditDialog>
  </page-list>
</template>

<script>
import EditDialog from "./editDialog";
import { getShopboxList, deleteById,listByPage } from "@/apis/unidrink/shopbox";
import { getShopOptions } from "@/apis/unidrink/shop";
import { cloneDeep } from "lodash";
import { msgErr, msgSuc } from "@/helpers/message";

export default {
  components: { EditDialog },
  props: {
    mode: {
      type: String,
      default: "view" // view-页面；component-组件
    }
  },
  data() {
    return {
      tableData: [],
      paginationData: {},
      initForm: {
        sort: "id",
        order: "desc",
      },
      form: {},
      total: 0,
      shopOptions: [],
      loading: false,
      dialogVisible: false,
      editMode: "add",
      editData: {},
    };
  },
  created() {},
  mounted() {
    getShopOptions(this.shopOptions);
    if (this.mode == "component") {
      this.getList();
    }
  },
  activated() {
    this.form = cloneDeep(this.initForm);
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      listByPage({ ...this.form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    dateFormatter(row) {
      return row.createtime
        ? this.$moment.unix(row.createtime).format("yyyy-MM-DD HH:mm:ss")
        : "无";
    },

    sortChangeHandle({ prop, order }) {
      if (prop && order) {
        this.form = {
          sort: prop,
          order: order === "descending" ? "desc" : "asc",
        };
      } else {
        this.form = cloneDeep(this.initForm);
      }
      this.getList();
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteById(row.id).then(({ code, result, message }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(message);
          }
        });
      });
    },

    editHandle(row) {
      if (this.mode == "component") {
        this.$emit("choose", row);
      } else {
        this.editMode = "edit";
        this.dialogVisible = true;
        this.editData = row;
      }
    },

    shopFormatter(row,column,cellValue,index){
      for(let i=0;i<this.shopOptions.length;i++){
        let shopInfo = this.shopOptions[i];
        if(shopInfo.id == cellValue){
          return shopInfo.name;
        }
      }
      return cellValue;
    }
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div class="body-ctan">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="展示店铺：" prop="shopId">
            <el-select clearable filterable v-model="form.shopId" placeholder="请选择">
              <el-option
                v-for="(item, index) in shopOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="包厢名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getShopList } from "@/apis/auth";
import { save, updateById } from "@/apis/unidrink/shopbox";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
    shopOptions:{
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      initForm: {
        name: "",
        shopId: "",
      },
      form: {},
      rules: {
        name: [
          { required: true, message: "包厢名称不能为空", trigger: "blur" },
        ],
        shopId: [
          { required: true, message: "展示店铺不能为空", trigger: "blur" },
        ],
      },
      adminOptions: [],
      printOptions: [],
    };
  },

  emits: ["update:modelValue", "refresh"],

  created() {
    this.form = cloneDeep(this.initForm);
  },

  watch: {
    modelValue(val) {
      if (val) {
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit") {
        this.form = cloneDeep(this.editData);
        this.form.starttime = new Date(Number(this.form.starttime) * 1000);
        this.form.endtime = new Date(Number(this.form.endtime) * 1000);
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        console.log(this.form);

        const promise = this.mode === "edit" ? updateById : save;

        promise(this.form).then(({ code, message }) => {
          if (code === "0000") {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        });
      });
    },
  },
};
</script>
,
<style lang="scss" scoped></style>

import http from "@/helpers/http";
import store from "@/stores";
import { msgErr } from "@/helpers/message";

export function verifyLogin() {
  return http.request({
    url: "/addons/voicenotice/notice/voice",
    method: "get",
  });
}

export function login(form) {
  return http.request({
    url: "/faAdmin/login",
    method: "post",
    data: {
      body: {
        userName: form.username,
        password: form.password,
      },
    },
  });
}

export function getPermission(userId) {
  return http.request({
    url: `/faAuthRule/findMenuTree?userId=${userId}`,
    method: "post",
    data: {},
  });
}

export function getCosAuth() {
  return http.request({
    url: "/cos/getCredential",
    method: "post",
    data: {},
  });
}

export function getRegion(province, city) {
  return http.request({
    url: "/SYTdknLasJ.php/unidrink/shop/area",
    method: "get",
    params: {
      "row[province]": province,
      "row[city]": city,
    },
  });
}
export function getStopListByUser(adminId, page, limit) {
  return http.request({
    url: "/faUnidrinkShop/listByPage",
    method: "post",
    data: {
      body: {
        adminId,
      },
    },
    params: {
      page,
      pageSize: limit,
    },
  });
}

// export async function getShopOptions(shopOptions = [], all = true) {
//   const page = 1;
//   const limit = 10;
//   const adminId = store.getters.userInfo.id;
//   shopOptions.splice(0, shopOptions.length);
//   // if (all) {
//   //   shopOptions.push({ name: "全部", id: 0 });
//   // }
//   const fn = async (page, limit) => {
//     await getStopListByUser(adminId, page, limit).then(
//       ({ code, result, message }) => {
//         if (code !== "0000") {
//           msgErr(message);
//           return;
//         }
//         const { list, total } = result;
//         shopOptions.push(...list);
//         if (page * limit < total) {
//           fn(page + 1, limit);
//         }
//       }
//     );
//   };
//   await fn(page, limit);
// }
